import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import LeadForm from './LeadForm';

const SUBMIT_NAERINGSSERVICE_LEAD = gql`
	mutation submitNaeringsserviceLead(
		$event: String!
		$formData: JSON!
		$entry: String!
	) {
		success: submitNaeringsserviceLead(
			event: $event
			formData: $formData
			entry: $entry
		)
	}
`;

/**
 * Represents a custom lead form for Næringsservice
 * @param {...object} props - The properties for the form
 * @param {string} [title='Be om å bli kontaktet'] - The title of the form
 * @param {string} [text='Fyll ut skjemaet under, så tar vi kontakt med deg.'] - The text of the form
 * @param {string} [className='naeringsservice-lead-form'] - The class name of the form
 * @param {object} [submitTexts] - The submit texts of the form
 * @param {object} [formProps] - The form properties
 * @returns {JSX.Element} - The form
 * @throws {error} - The error from the backend
 **/
export default function NaeringsserviceLead({
	title = 'Be om å bli kontaktet',
	text = 'Fyll ut skjemaet under, så tar vi kontakt med deg.',
	className = 'naeringsservice-lead-form',
	submitTexts = {
		submitting: 'Sender henvendelse',
		submit: 'Send inn',
		submitted: 'Tusen takk for din henvendelse.',
	},
	formProps,
	segmentType,
	centeredThankYou = true,
}) {
	const [saving, setSaving] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	// Mutation to submit lead
	const [submitNaeringsserviceLead, { loading, error }] = useMutation(
		SUBMIT_NAERINGSSERVICE_LEAD
	);

	async function onSubmit(values) {
		try {
			if (!values?.email) throw new Error('E-mail is required');

			setSaving(true);

			const response = await submitNaeringsserviceLead({
				variables: {
					event: 'Form Submitted',
					formData: {
						name: values?.name,
						phone: values?.phone,
						email: values?.email,
						url: formProps,
					},
					entry: `Nytt lead fra Næringsservice - ${
						segmentType || document?.title
					}`,
				},
			});

			if (!response?.data?.success) {
				throw new Error('Lead submission failed');
			}

			setSaving(false);
			setSubmitted(true);
			return response?.data?.success;
		} catch (e) {
			setSaving(false);
			console.log(`NaeringsserviceLead Error: ${e?.message}`);
		}
	}

	return (
		<LeadForm
			title={title}
			text={text}
			event={`Nytt lead fra Næringsservice - ${
				segmentType || document?.title
			}`}
			className="naeringsservice-lead-form"
			onSubmit={onSubmit}
			loading={loading || saving}
			error={error}
			resolver="submitNaeringsserviceLead"
			centeredThankYou={centeredThankYou}
			submitted={submitted}
			submitTexts={submitTexts}
		/>
	);
}
