import React, { useState, forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';

import { removeSpecialCharacters } from 'libs/content';

const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	outline: 1px solid ${p => p.theme.colors.grey400};
	background: ${p => p.theme.colors.grey200};
	border-radius: ${p => p.theme.utils.borderRadius};
	outline-offset: -2px;
	transition: all 0.2s ease-out;
	line-height: 26px;

	input {
		margin: 0;
		display: flex;
		flex-direction: column;
		width: auto;
		min-width: 100px;
		border: none;
		flex-grow: 1;
		background: transparent;

		&::-webkit-search-cancel-button {
			appearance: none;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
		}

		&:focus {
			outline: 0;
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 0 20px;

	label {
		color: ${p => p.theme.colors.grey700};
		font-size: 17px;
		font-weight: 400;
		line-height: 26px;
		pointer-events: none;
		position: absolute;
		transform: translate(15px, 15px) scale(1);
		transform-origin: top left;
		transition: all 0.2s ease-out;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: calc(100% - 52px);

		.required-indicator {
			color: ${p => p.theme.colors.coral900};
			margin-left: 5px;
		}
	}

	svg {
		color: ${p => p.theme.colors.grey700};
	}

	&.leading-icon {
		${SearchContainer} {
			padding-right: 34px;
			padding-left: 52px;

			input {
				padding: 8px 34px 8px 0px;
				flex: 1;
			}
		}

		label {
			transform: translate(52px, 15px) scale(1);
		}
	}

	&:hover {
		${SearchContainer} {
			outline: 1px solid ${p => p.theme.colors.grey700};
		}
	}
	&.active {
		${SearchContainer} {
			background: ${p => p.theme.colors.white};
			outline: 1px solid ${p => p.theme.colors.grey900};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.grey900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
	}
	&:focus-within {
		${SearchContainer} {
			background: ${p => p.theme.colors.white};
			outline: 2px solid ${p => p.theme.colors.blue600};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.grey900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
		svg {
			color: ${p => p.theme.colors.grey900};
		}
	}
	&.help-text {
		margin-bottom: 47px;
	}
	&.error {
		margin-bottom: 47px;
		${SearchContainer} {
			background: ${p => p.theme.colors.white};
			outline: 2px solid ${p => p.theme.colors.coral900};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.coral900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
		svg {
			color: ${p => p.theme.colors.coral900};
		}
	}
`;

const LoadingWrap = styled.span`
	position: absolute;
	right: 20px;
	top: 0;
	height: 56px;
	width: 30px;
	font-size: 17px;
	display: none;
	align-items: center;
	justify-content: flex-end;
	text-align: center;

	.loading & {
		display: flex;
	}
`;

const IconLoading = styled(FontAwesomeIcon)`
	font-size: 17px;
	animation-duration: 1s;
	color: ${p => p.theme.colors.grey700};
`;

const ArrowBtn = styled.button`
	position: absolute;
	right: 2px;
	top: 2px;
	height: calc(100% - 4px);
	max-height: 53px;
	width: 30px;
	font-size: 17px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	background-color: transparent;
	border: 0;
	padding: 0px 25px;
	margin: 0 !important;
	cursor: pointer;
	transition: all 0.2s ease-out;
	border-left: 1px solid transparent;
	&:focus,
	&:hover {
		outline: 0;
		border-left: 1px dotted ${p => p.theme.colors.grey700};
	}
	svg {
		color: ${p => p.theme.colors.black} !important;
	}
`;

const List = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 3;
	background: ${p => p.theme.colors.white};
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 15px 0;
	max-height: 265px;
	overflow-y: scroll;
	p {
		padding: 10px 20px;
		margin: 0;
	}
`;

const ListItem = styled.button`
	padding: 10px 20px;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	display: block;
	width: 100%;
	text-align: left;
	margin: 0 !important;
	color: ${p =>
		(p.$isChosen && p.theme.colors.blue600) || p.theme.colors.black};
	&:hover {
		background: ${p => p.theme.colors.blue200};
	}
`;

const IconWrap = styled.span`
	position: absolute;
	right: 20px;
	top: 0;
	height: 56px;
	width: 30px;
	font-size: 17px;
	display: flex;
	align-items: center;
	text-align: center;

	.leading-icon & {
		right: auto;
		left: 20px;
	}
`;
const Error = styled.span`
	position: absolute;
	left: 0;
	top: calc(100% + 5px);
	color: ${p => p.theme.colors.coral900};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
`;
const HelpText = styled(Error)`
	color: ${p => p.theme.colors.grey700};
	a {
		color: ${p => p.theme.colors.grey700};
	}
`;

const SelectField = forwardRef(
	(
		{
			label,
			placeholder,
			error,
			helpText,
			icon,
			loading = false,
			textarea = false,
			leadingIcon = false,
			options = [],
			onChange,
			onClick,
			...props
		},
		ref
	) => {
		const [query, setQuery] = useState('');
		const [isOpen, setIsOpen] = useState(false);
		const [selected, setSelected] = useState(
			props?.value?.label || props?.value
		);
		const wrapRef = useRef(null);

		// Define classes based on error, active, help-text and icon
		let classes = selected ? 'active' : '';
		if (error) {
			classes += ' error';
		}
		if (helpText) {
			classes += ' help-text';
		}
		if (icon) {
			classes += ' leading-icon';
		}
		if (loading) {
			classes += ' loading';
		}

		const text = label || placeholder;

		const Icon = icon?.prefix ? FontAwesomeIcon : icon;

		// Filter options based on if matches the query or not
		const filter = options => {
			// If matches prop exists then filter based on that
			if (props?.matches?.length > 0) {
				return options?.filter(option =>
					props?.matches?.includes(option)
				);
			} else {
				// If no matches prop exists then filter based on query
				return options?.filter(
					option =>
						option?.toLowerCase().indexOf(query.toLowerCase()) > -1
				);
			}
		};

		// Get display value to show in input
		const getDisplayValue = () => {
			if (query) return query;
			if (selected) return selected;
			return '';
		};

		// Close dropdown if clicking outside when open
		function handleClickOutside(event) {
			if (!isOpen) return;
			if (wrapRef?.current && !wrapRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		}

		// Close dropdown if pressing escape when open
		function handleKeyDown(event) {
			if (!isOpen) return;
			if (event.key === 'Escape') {
				setIsOpen(false);
				if (ref?.current) ref.current.blur();
			}
		}

		// If the dropdown-list is open and input-ref exists then set focus to the input-ref
		useEffect(() => {
			if (isOpen && ref?.current) ref?.current?.focus();

			document.addEventListener('click', handleClickOutside, true);
			document.addEventListener('keydown', handleKeyDown, true);
			return () => {
				document.removeEventListener('click', handleClickOutside, true);
				document.removeEventListener('keydown', handleKeyDown, true);
			};
			//eslint-disable-next-line
		}, [isOpen, ref?.current?.wrapRef?.current]);

		// If no options exists then return nothing
		if (!options?.length > 0 && !loading) return null;

		return (
			<Wrapper
				className={classes}
				ref={wrapRef}
				data-cy={props['data-cy'] || 'select-field'}>
				<SearchContainer
					onClick={() => {
						if (loading) return;
						setIsOpen(!isOpen);
					}}>
					<input
						type="text"
						id={props?.id}
						name={props?.name}
						ref={ref}
						value={getDisplayValue()}
						required={props?.required}
						onChange={e => {
							// Save to query state
							setQuery(e.target.value);

							// If value has length then open the dropdown
							if (e.target.value.length > 0) setIsOpen(true);

							if (onChange) onChange(e);

							// If is not multiselect-variant then save to state and don't proceed
							setSelected(e.target.value);
							return;
						}}
					/>
				</SearchContainer>

				{(loading && (
					<LoadingWrap className="loading-wrapper">
						<IconLoading
							icon={faCircleNotch}
							className="fa-spin"
							width="20"
							height="20"
						/>
					</LoadingWrap>
				)) || (
					<ArrowBtn
						aria-controls="dropdown-list"
						onClick={e => {
							e.preventDefault();
							setIsOpen(!isOpen);
						}}>
						<FontAwesomeIcon
							icon={(isOpen && faCaretUp) || faCaretDown}
						/>
					</ArrowBtn>
				)}
				{text && (
					<label>
						{text}

						{props.required && (
							<span className="required-indicator">*</span>
						)}
					</label>
				)}
				{isOpen && (
					<List
						id="dropdown-list"
						tabIndex="-1"
						role="listbox"
						data-cy="selectfield-list">
						{filter(options).map((option, i) => {
							const optionId = removeSpecialCharacters(option);
							return (
								<ListItem
									key={optionId}
									role="option"
									$isChosen={
										typeof selected === 'string' &&
										selected === option
									}
									onClick={e => {
										// If already selected don't proceed
										if (selected === option) {
											setIsOpen(!isOpen);
											if (onClick) onClick(e);
											return;
										}

										if (onClick) onClick(e);

										// Empty query value
										setQuery(() => '');

										// Save to state (overwrite if is not multi, and push if is multi)
										setSelected(option);

										// Close the dropdown-list
										setIsOpen(!isOpen);
									}}>
									{option}
								</ListItem>
							);
						})}

						{filter(options)?.length === 0 && (
							<p>Ingen resultater ...</p>
						)}
					</List>
				)}
				{icon && (
					<IconWrap>
						{icon?.prefix ? <Icon icon={icon} /> : <Icon />}
					</IconWrap>
				)}
				{error && (
					<>
						<IconWrap>
							<FontAwesomeIcon icon={faCircleExclamation} />
						</IconWrap>

						<Error>{error?.message || error}</Error>
					</>
				)}
				{!error && helpText && <HelpText>{helpText}</HelpText>}
			</Wrapper>
		);
	}
);

export default SelectField;
