import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { track } from 'context/AnalyticsProvider';
import { getLinkType, isExternalUrl, stripHash } from 'libs/content';
import { smoothScrollToAnchor } from './Button';
import useWindow from 'context/useWindow';
import { AnchorLink } from 'components/Link';

const styles = p => `
	display: inline-flex;
	align-items: center;
	border: none;
	color: ${p.theme.colors.blue600};
	background: transparent;
	padding: 0;
	font-size: 17px;
	text-decoration: underline;
	line-height: 22px;
	transition: all 0.2s ease-in-out;
	outline-offset: -2px;

	&:hover, &:focus  {
		background: transparent;
		border: none;
		color: ${p.theme.colors.blue800};
	}

	&.white {
		color: ${p.theme.colors.white};
		&:hover {
			color: ${p.theme.colors.blue200};
		}
	}
`;

const StyledButton = styled.button`
	${p => styles(p)}
`;

const StyledAnchor = styled(AnchorLink)`
	${p => styles(p)}
`;

/**
 * Blank Button Component
 *
 * A customizable button component that supports various styles and features.
 *
 * @param {object} props - The props for the button component.
 * @param {string} [title] - The title for the button
 * @param {string} [className] - Additional CSS classes to be applied to the button.
 * @param {React.ReactNode} children - The content to be displayed within the button.
 * @param {boolean} [tracking=true] - Whether button clicks should be tracked (default is true).
 * @param {Function} [onClick] - The click event handler for the button.
 * @param {object} [...rest] - Additional props to be spread on the button component.
 *
 * @param {React.Ref} ref - A React ref for the button component.
 *
 * @returns {React.ReactNode} A React component representing a customizable button.
 */
const BlankButton = forwardRef(
	(
		{
			title,
			className,
			children,
			tracking = true,
			onClick = () => {},
			...props
		},
		ref
	) => {
		const { windowHeight, windowWidth } = useWindow();

		// If "to"-prop is object then override with the slug, and make sure the slug has a slash as first character if not set already
		if (props?.to?.slug)
			props.to =
				props?.to?.slug?.charAt(0) === '/'
					? props?.to?.slug
					: `/${props?.to?.slug}`;

		// Determine link type (anchor, email, phone, url)
		const linkType = getLinkType(props?.href || props?.to);

		// Determine if link is external
		const isExternal = isExternalUrl(props?.href);

		// Add classes
		let classes = className ? className : '';
		if (props?.white) classes += ' white';

		// Add click event handler
		props.onClick = e => {
			if (isExternal) return;
			e.preventDefault();

			if (tracking) {
				track('Button Clicked', {
					label: title,
					category: window.location.pathname,
					href: props?.href || props?.to,
				});
			}

			// Hash anchor
			if (props?.href && linkType === 'anchor') {
				e.preventDefault();
				const element = stripHash(props.href);
				smoothScrollToAnchor(element, windowHeight, windowWidth < 768);
				// Internal link
			} else if (props?.to) {
				navigate(props?.to);
				// External link
			} else {
				onClick(e);
			}

			onClick(e);

			return;
		};

		// Add keydown event for Enter key
		props.onKeyDown = e => {
			if (e.key === 'Enter') {
				props.onClick(e);
			} else {
				return;
			}
		};

		if ((isExternal && !!props?.href) || props?.targetblank) {
			return (
				<StyledAnchor
					ref={ref}
					className={classes}
					title={title}
					target="_blank"
					rel="noreferrer"
					tracking={true}
					href={props?.href || props?.to}>
					{children || title}
				</StyledAnchor>
			);
		}

		return (
			<StyledButton
				ref={ref}
				className={classes}
				title={title}
				{...props}>
				{children || title}
			</StyledButton>
		);
	}
);

export default BlankButton;
