import React, { useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import debounce from 'lodash/debounce';

import { PrivacyPolicyLink } from 'components/consent-portal/useSaveConsent';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import Error from 'components/error/Error';
import Checkbox from '../NewCheckbox';
import { Req } from './Fields';

/**
 * Represents a custom lead form for Elektro
 * @returns {HTMLElement} The custom lead form
 **/
export default function ElektroLeadForm() {
	const [saving, setSaving] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState('');
	const [data, setData] = useState({
		name: '',
		phone: '',
		consent: false,
	});

	const client = useApolloClient();

	function handleConsentChange(e) {
		if (!e?.target) return;
		const isChecked = e?.target?.checked;
		if (isChecked) {
			e.target.setAttribute('checked', true);
			setData({ ...data, consent: true });
		} else {
			e.target.removeAttribute('checked');
			setData({ ...data, consent: false });
		}
	}

	const debouncedOnChange = debounce((value, field) => {
		if (value?.length === 0) {
			setData({ ...data, [field]: '' });
			return;
		}
		setData({ ...data, [field]: value });
	}, 500);

	async function handleOnSubmit(e) {
		e.preventDefault();
		try {
			const form = e?.target;

			if (!form.phone.value) {
				setError('Telefonnummer er påkrevd');
				return;
			}
			if (!form.name.value) {
				setError('Navn er påkrevd');
				return;
			}

			setSaving(true);
			setError('');

			const { loading: submitLoading, error: submitError } =
				await client.mutate({
					mutation: gql`
						mutation sendElektroLead(
							$name: String!
							$phone: String!
							$page: PageInput!
						) {
							success: sendElektroLead(
								name: $name
								phone: $phone
								page: $page
							)
						}
					`,
					variables: {
						name: form?.name?.value,
						phone: form?.phone?.value,
						page: {
							title: document?.title
								? document?.title?.replace(' - NTE', '').trim()
								: 'Elektro',
							url: window?.location?.href,
						},
					},
				});

			if (!submitLoading && submitError) {
				throw new Error(submitError?.message);
			}

			setSaving(false);
			setSubmitted(true);
		} catch (e) {
			console.error(`Mutation sendElektroLead failed: ${e.message}`);
			setSaving(false);
			setError(
				e.message ||
					'Noe gikk galt med innsendingen. Prøv igjen senere.'
			);
		}
	}

	if (!saving && submitted) {
		return (
			<p
				data-cy="elektro-lead-form-success"
				style={{ marginBottom: '0' }}>
				Takk for din henvendelse. Vi ringer deg så fort som mulig.
			</p>
		);
	}

	return (
		<form onSubmit={e => handleOnSubmit(e)}>
			<InputField
				id="name"
				name="name"
				label="Navn"
				placeholder="Navn"
				data-cy="lead-input-name"
				onChange={e => debouncedOnChange(e?.target?.value, 'name')}
				required
			/>

			<InputField
				id="phone"
				name="phone"
				type="tel"
				label="Telefon"
				placeholder="Telefon"
				data-cy="lead-input-phone"
				onChange={e => debouncedOnChange(e?.target?.value, 'phone')}
				required
			/>

			<Checkbox
				tabIndex={0}
				id="store-consent"
				name="store-consent"
				data-cy="lead-input-store-consent"
				defaultChecked={data?.consent}
				onChange={e => {
					handleConsentChange(e);
				}}
				onKeyDown={e => {
					if (!['Enter', 'Space'].includes(e?.key)) return;
					handleConsentChange(e);
				}}
				required>
				<span data-cy="store-consent.label" style={{ fontWeight: 400 }}>
					Jeg samtykker til at min info lagres av NTE
				</span>
				<Req> *</Req>
			</Checkbox>

			<Button
				type="submit"
				data-cy="elektro-lead-form-submit"
				title="Send inn"
				loading={(!!saving && 'true') || 'false'}
				disabled={
					saving || !data?.consent || !data?.phone || !data?.name
				}>
				Bli oppringt
			</Button>
			<PrivacyPolicyLink
				style={{ marginTop: '20px', marginBottom: '0' }}
				text="Les NTE sin personvernerklæring"
			/>

			{!saving && error && (
				<Error
					text="Noe gikk galt med innsendingen. Prøv igjen senere."
					resolver="sendElektroLead"
					errorMessage={error}
				/>
			)}
		</form>
	);
}
