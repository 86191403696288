import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faUpRightFromSquare';
import { faArrowDownLong } from '@fortawesome/pro-regular-svg-icons/faArrowDownLong';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons/faArrowRightLong';
import { faWavePulse } from '@fortawesome/pro-regular-svg-icons/faWavePulse';
import { faWifi } from '@fortawesome/pro-regular-svg-icons/faWifi';
import { faChargingStation } from '@fortawesome/pro-regular-svg-icons/faChargingStation';
import { faPlug } from '@fortawesome/pro-regular-svg-icons/faPlug';
import { faUserTie } from '@fortawesome/pro-regular-svg-icons/faUserTie';
import { faHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faShip } from '@fortawesome/pro-regular-svg-icons/faShip';
import { faTractor } from '@fortawesome/pro-regular-svg-icons/faTractor';
import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons/faRightToBracket';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faTruckDroplet } from '@fortawesome/pro-regular-svg-icons/faTruckDroplet';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowDownToBracket';
import { faTransformerBolt } from '@fortawesome/pro-regular-svg-icons/faTransformerBolt';
import { faWave } from '@fortawesome/pro-regular-svg-icons/faWave';
import { faWindTurbine } from '@fortawesome/pro-regular-svg-icons/faWindTurbine';
import { faDropletDegree } from '@fortawesome/pro-regular-svg-icons/faDropletDegree';
import { faWindsock } from '@fortawesome/pro-regular-svg-icons/faWindsock';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faMobileNotch } from '@fortawesome/pro-regular-svg-icons/faMobileNotch';
import { faBuildingMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faBuildingMagnifyingGlass';
import { faCity } from '@fortawesome/pro-regular-svg-icons/faCity';
import { faSitemap } from '@fortawesome/pro-regular-svg-icons/faSitemap';
import { faPersonDigging } from '@fortawesome/pro-regular-svg-icons/faPersonDigging';

import useWindow from 'context/useWindow';
import { track } from 'context/AnalyticsProvider';
import {
	getLinkType,
	getUrlByType,
	isExternalUrl,
	scrollToElement,
	stripHash,
} from 'libs/content';
import { AnchorLink } from 'components/Link';

const styles = p => `
	position: relative;
	display: inline-flex;
	align-items: center;
	border-radius: ${p.theme.utils.borderRadius};
	border: none;
	background: ${p.theme.colors.blue600};
	color: white;
	padding: 11px 20px;
	margin: 0;
	font-size: 17px;
	font-weight: 500;
	line-height: 22px;
	vertical-align: middle;
	transition: all 0.2s ease-in-out;
	outline-offset: -2px;
	text-decoration: none;

	&.small {
		padding: 9px 14px;
		line-height: 18px;
		font-size: 14px;
		&.has-icon {
			padding-left: 12px;
		}
	}
	&.arrow:hover .icon-wrap {
		transform: translateY(4px);
	}
	&.stretch {
		display: flex;
		width: 100%;
    	justify-content: center;
	}
	&.dark {
		background: ${p.theme.colors.blue300};
		color: ${p.theme.colors.blue700};
	}
	&.has-icon {
		padding-left: 16px;
	}
	&.outlined {
		background: white;
		color: ${p.theme.colors.blue600};
		outline: 2px solid ${p.theme.colors.blue600};
		&:hover {
			background: white;
		}
		&.dark {
			background: ${p.theme.colors.grey900};
			outline-color: ${p.theme.colors.blue300};
			color: ${p.theme.colors.blue300};
		}
	}

	&.red {
		outline: 2px solid ${p.theme.colors.coral700} !important;
		color: ${p.theme.colors.coral700} !important; 
	}

	&:hover, &.hover, &:focus  {
		background: ${p.theme.colors.blue700};
		color: white;
		&.dark {
			background: ${p.theme.colors.blue200};
			color: ${p.theme.colors.blue700};
		}

		&.outlined {
			background: white;
			outline-color: ${p.theme.colors.blue700};
			color: ${p.theme.colors.blue700};
			&.dark {
				background: ${p.theme.colors.grey900};
				outline-color: ${p.theme.colors.blue200};
				color: ${p.theme.colors.blue200};
			}
		}
	}

	&:disabled {
		background: ${p.theme.colors.grey500};
		cursor: not-allowed;

		&.dark {
			background-color: ${p.theme.colors.grey700};
			color: ${p.theme.colors.grey300};
		}
		&.outlined {
			background: white;
			outline-color: ${p.theme.colors.grey500};
			color: ${p.theme.colors.grey500};

			&.dark {
				background: ${p.theme.colors.grey900};
				outline-color: ${p.theme.colors.grey500};
				color: ${p.theme.colors.grey500};
			}
		}
	}
`;

const StyledButton = styled.button`
	${p => styles(p)}
`;
const StyledLink = styled(AnchorLink)`
	${p => styles(p)}
`;

const IconWrap = styled.span`
	font-size: 17px;
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0 8px 0 0;
	transition: transform 350ms ease;
	&.loading-icon {
		margin: 0 0 0 8px;
	}
	&.small {
		margin: 0 6px 0 0;
		font-size: 14px;
		&.loading-icon {
			margin: 0 0 0 6px;
		}
	}
`;

/**
 * Button Component
 *
 * A customizable button component that supports various styles and features.
 *
 * @param {object} props - The props for the button component.
 * @param {React.ElementType} icon - An optional icon to display within the button.
 * @param {string} [outlined='false'] - Whether the button should have an outlined style.
 * @param {boolean} [small=false] - Whether the button should be rendered as a small-sized button.
 * @param {boolean} [dark=false] - Whether the button should have a dark theme.
 * @param {boolean} [stretch=false] - Whether the button should stretch to fill its container.
 * @param {string} [className] - Additional CSS classes to be applied to the button.
 * @param {React.ReactNode} children - The content to be displayed within the button.
 * @param {boolean} [tracking=true] - Whether button clicks should be tracked (default is true).
 * @param {Function} [onClick] - The click event handler for the button.
 * @param {object} [...rest] - Additional props to be spread on the button component.
 *
 * @param {React.Ref} ref - A React ref for the button component.
 *
 * @returns {React.ReactNode} A React component representing a customizable button.
 */
const Button = forwardRef(
	(
		{
			icon,
			outlined = 'false',
			small = false,
			dark = false,
			stretch = false,
			className = '',
			children,
			tracking = true,
			onClick = () => {},
			...props
		},
		ref
	) => {
		const { windowHeight, windowWidth } = useWindow();

		// If "to"-prop is object then override with the slug, and make sure the slug has a slash as first character if not set already
		if (props?.to?.slug)
			props.to =
				props?.to?.slug?.charAt(0) === '/'
					? props?.to?.slug
					: `/${props?.to?.slug}`;

		// Determine link type (anchor, email, phone, url)
		const linkType = getLinkType(props?.href || props?.to);

		// Contentful props for komponent-Knapp
		if (props) {
			outlined =
				props?.btntype === 'Sekundær' || outlined === 'true'
					? 'true'
					: 'false';
			small = props?.size === 'Liten' || small;
			dark = props?.mode === 'Mørk' || dark;
		}

		// Icon
		const Icon = icon?.prefix
			? () => <FontAwesomeIcon icon={icon} />
			: typeof icon === 'string' && icon
			? () => <FontAwesomeIcon icon={getFontAwesomeIcon(icon)} />
			: icon;

		// Button as default element
		let Component = StyledButton;

		// If external link then render <a> instead
		if (props.href) {
			Component = StyledLink;
			props.type = 'button';
			if (isExternalUrl(props?.href)) {
				props.rel = 'noreferrer';
				props.target = '_blank';
			}
		}

		// If internal link then override "to"-prop with slug from reference-field
		if (props?.linkref?.slug && props?.linkref?.__typename) {
			props.to = getUrlByType(
				props?.linkref?.slug,
				props?.linkref?.__typename
			);
		}

		// If button should open in new tab then render <a> instead with target="_blank"
		if (props?.targetblank) {
			props.target = '_blank';
			props.href = props?.to || props?.href;
			props.to = undefined;
			Component = StyledLink;
			props.type = 'button';
		}

		let classes = className;
		if (outlined === 'true' || props?.btntype === 'Sekundær') {
			classes += ' outlined';
		}
		if (small || props?.size === 'Liten') classes += ' small';
		if (dark || props?.mode === 'Mørk') classes += ' dark';
		if (stretch) classes += ' stretch';
		if (icon) classes += ' has-icon';
		if (icon === 'faArrowDown') classes += ' arrow';

		props.onClick = e => {
			if (tracking) {
				track('Button Clicked', {
					label: children,
					category: window.location.pathname,
					href: props?.href || props?.to,
				});
			}
			// Hash anchor
			if (props?.href && linkType === 'anchor') {
				if (e) e?.preventDefault();
				const element = stripHash(props.href);
				smoothScrollToAnchor(element, windowHeight, windowWidth < 768);
				// Internal link
			} else if (props?.to) {
				navigate(props?.to);
				// External link
			} else {
				onClick(e);
			}

			onClick(e);

			return;
		};

		// Add keydown event for Enter key
		props.onKeyDown = e => {
			if (e.key === 'Enter') {
				props.onClick(e);
			} else {
				return;
			}
		};

		return (
			<Component
				ref={ref}
				className={classes}
				{...props}
				disabled={
					props?.disabled || props?.loading === 'true' || false
				}>
				{icon && (
					<IconWrap
						className={`icon-wrap ${
							small || props?.size === 'Liten' ? 'small' : ''
						}`}>
						<Icon />
					</IconWrap>
				)}
				{children}
				{props?.loading === 'true' && (
					<IconWrap
						className={`icon-wrap loading-icon ${
							small || props?.size === 'Liten' ? 'small' : ''
						}`}>
						<FontAwesomeIcon
							icon={faCircleNotch}
							className="fa-spin"
						/>
					</IconWrap>
				)}
			</Component>
		);
	}
);

// Smooth-scroll function for anchor links
export function smoothScrollToAnchor(id, windowHeight, isMobile) {
	if (!id) return;

	// Captilazie first letter in id
	const elementId = id?.charAt(0).toUpperCase() + id?.slice(1);

	// Get element by id
	const elementById = document.getElementById(id);
	// Get element by similar id
	const elementWithSimilarId = document.querySelector(`[id*="${elementId}"]`);
	const element = elementById || elementWithSimilarId;

	if (!id || !element) {
		console.error(`Element with id "${id}" not found, could not scroll.`);
		return;
	}

	const elementHigherThanViewport = windowHeight < element?.clientHeight;

	return setTimeout(() => {
		scrollToElement(
			element,
			elementHigherThanViewport ? 0 : isMobile ? 100 : 150
		);
	}, 250);
}

/**
 * Translates the icon string to a FontAwesome icon
 */
export function getFontAwesomeIcon(name) {
	if (!name) return;

	const icons = {
		faArrowDown,
		faArrowDownLong,
		faArrowRightLong,
		faUpRightFromSquare,
		faWavePulse,
		faWifi,
		faChargingStation,
		faPlug,
		faUserTie,
		faHeadset,
		faShip,
		faTractor,
		faRightToBracket,
		faBell,
		faTruckDroplet,
		faCircleNotch,
		faArrowDownToBracket,
		faTransformerBolt,
		faWave,
		faWindTurbine,
		faDropletDegree,
		faWindsock,
		faPhone,
		faMobileNotch,
		faBuildingMagnifyingGlass,
		faCity,
		faSitemap,
		faPersonDigging,
	};

	if (name === 'all') {
		return Object.keys(icons);
	}

	return icons[name];
}

export default Button;
