import React, { useState, forwardRef, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';

import Checkbox from './NewCheckbox';
import { removeSpecialCharacters } from 'libs/content';

const BtnText = styled.span`
	color: ${p => p.theme.colors.grey700};
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Label = styled.span`
	color: ${p => p.theme.colors.grey700};
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	pointer-events: none;
	position: absolute;
	transform: translate(0, 0) scale(1);
	transform-origin: top left;
	transition: all 0.2s ease-out;

	.required-indicator {
		color: ${p => p.theme.colors.coral900};
		margin-left: 5px;
	}
`;

const BtnListTrigger = styled.button`
	min-height: 56px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 15px;
	outline: 1px solid ${p => p.theme.colors.grey400};
	background: ${p => p.theme.colors.grey200};
	color: ${p => p.theme.colors.grey700};
	border-radius: ${p => p.theme.utils.borderRadius};
	outline-offset: -2px;
	transition: all 0.2s ease-out;
	line-height: 26px;
	margin: 0 !important;
	svg {
		color: ${p => p.theme.colors.grey700} !important;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	${p =>
		p.$hasValue &&
		css`
			color: ${p => p.theme.colors.grey900};
			${BtnText} {
				color: ${p => p.theme.colors.grey900};
			}
		`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 0 20px;

	&.leading-icon {
		${Label} {
			flex: 1;
			transform: translate(0, 0) scale(1);
		}
		&:not(.active) {
			${Label} {
				transform: translate(34px, 0) scale(1);
			}
		}
		${BtnText} {
			margin-left: 34px;
		}
	}

	&:hover {
		${BtnListTrigger} {
			outline: 1px solid ${p => p.theme.colors.grey700};
		}
	}
	&.active {
		&:not(.leading-icon) {
			${BtnListTrigger} {
				padding-right: 35px;
			}
		}
	}
	&:focus-within,
	&.active {
		${BtnListTrigger} {
			background: ${p => p.theme.colors.white};
			outline: 1px solid ${p => p.theme.colors.grey900};
		}
		${Label} {
			background-color: ${p => p.theme.colors.white};
			color: ${p => p.theme.colors.grey900};
			line-height: 22px;
			padding: 0 5px;
			transform: translate(0px, -28px) scale(0.82) !important;
		}
		svg {
			color: ${p => p.theme.colors.grey900};
		}
	}
	&:focus-within {
		${BtnListTrigger} {
			outline: 2px solid ${p => p.theme.colors.blue600};
		}
	}
	&.help-text {
		margin-bottom: 47px;
	}
	&.error {
		margin-bottom: 47px;
		${BtnListTrigger} {
			background: ${p => p.theme.colors.white};
			outline: 2px solid ${p => p.theme.colors.coral900};
		}
		${Label} {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.coral900};
			line-height: 22px;
			transform: translate(0px, -28px) scale(0.82);
		}
		svg {
			color: ${p => p.theme.colors.coral900};
		}
	}
`;

const List = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 3;
	background: ${p => p.theme.colors.white};
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 15px 0;
	max-height: 265px;
	overflow: auto;
	p {
		padding: 10px 20px;
		margin: 0;
	}
	li {
		background-color: transparent;
		border: 0;
		display: block;
		width: 100%;
		text-align: left;
		margin: 0 !important;
		color: ${p =>
			(p.isChosen && p.theme.colors.blue600) || p.theme.colors.black};
		&:hover {
			background: ${p => p.theme.colors.blue200};
		}
		label {
			margin: 0;
			width: 100%;
			padding: 10px 20px;
			cursor: pointer;
		}
	}
`;

const IconWrap = styled.span`
	position: absolute;
	right: 20px;
	top: 0;
	height: 56px;
	width: 30px;
	font-size: 17px;
	display: flex;
	align-items: center;
	text-align: center;
	transform: none;

	.leading-icon & {
		right: auto;
		left: 20px;
	}
`;
const Error = styled.span`
	position: absolute;
	left: 0;
	top: calc(100% + 5px);
	color: ${p => p.theme.colors.coral900};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
`;
const HelpText = styled(Error)`
	color: ${p => p.theme.colors.grey700};
`;

const MultiSelectField = forwardRef(
	(
		{
			label,
			placeholder,
			error,
			helpText,
			icon,
			leadingIcon = false,
			options = [],
			onChange,
			...props
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState(props?.isOpen || false);
		const [selectedOptions, setSelectedOptions] = useState([]);

		const wrapRef = useRef(null);

		// Define classes based on error, active, help-text and icon
		let classes = selectedOptions?.length > 0 ? 'active' : '';
		if (error) {
			classes += ' error';
		}
		if (helpText) {
			classes += ' help-text';
		}
		if (icon) {
			classes += ' leading-icon';
		}

		const text = label || placeholder;

		const Icon = icon?.prefix ? FontAwesomeIcon : icon;

		// Close dropdown if clicking outside when open
		function handleClickOutside(event) {
			if (!isOpen) return;

			if (wrapRef?.current && !wrapRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		}

		// Close dropdown if pressing escape when open
		function handleKeyDown(event) {
			if (!isOpen) return;
			if (event.key === 'Escape') {
				setIsOpen(false);

				if (ref?.current) ref.current.blur();
			}
		}

		// Handle click on option in list
		const onOptionClicked = (name, event) => {
			let newArray = [...selectedOptions];
			let chosensIndex = newArray.indexOf(name);
			if (chosensIndex === -1) {
				newArray.push(name);
				event.target.setAttribute('checked', true);
			} else {
				newArray.splice(chosensIndex, 1);
				event.target.removeAttribute('checked');
			}
			setSelectedOptions(newArray);

			if (onChange) onChange(event, name);
		};

		useEffect(() => {
			if (!Array.isArray(props?.selected) || props?.selected.length === 0)
				return;
			setSelectedOptions(props?.selected);
		}, [props?.selected]);

		// If the dropdown-list is open and input-ref exists then set focus to the input-ref
		useEffect(() => {
			if (isOpen && ref?.current) ref?.current?.focus();

			document.addEventListener('click', handleClickOutside, true);
			document.addEventListener('keydown', handleKeyDown, true);
			return () => {
				document.removeEventListener('click', handleClickOutside, true);
				document.removeEventListener('keydown', handleKeyDown, true);
			};
			//eslint-disable-next-line
		}, [isOpen, ref?.current?.wrapRef?.current]);

		// If no options exists then return nothing
		//if (!options?.length > 0) return null;

		return (
			<Wrapper className={classes} ref={wrapRef}>
				<BtnListTrigger
					title={label}
					$hasValue={selectedOptions?.length > 0 || false}
					onClick={e => {
						e.preventDefault();
						setIsOpen(!isOpen);
					}}>
					<Label>
						{text}

						{props.required && (
							<em className="required-indicator">*</em>
						)}
					</Label>

					{selectedOptions?.length > 0 && (
						<BtnText>{selectedOptions.join(', ')}</BtnText>
					)}

					<FontAwesomeIcon
						icon={(isOpen && faCaretUp) || faCaretDown}
					/>
				</BtnListTrigger>

				{isOpen && (
					<List id="dropdown-list" tabIndex="-1" role="listbox">
						{options?.length > 0 &&
							options.map((option, i) => {
								const optionId =
									removeSpecialCharacters(option);

								return (
									<li
										key={`${optionId}-${selectedOptions?.includes(
											option
										)}`}>
										<Checkbox
											tabIndex="0"
											name={optionId}
											id={optionId}
											label={option}
											defaultChecked={selectedOptions?.includes(
												option
											)}
											onChange={e => {
												onOptionClicked(option, e);
											}}
										/>
									</li>
								);
							})}
					</List>
				)}
				{icon && (
					<IconWrap>
						{icon?.prefix ? <Icon icon={icon} /> : <Icon />}
					</IconWrap>
				)}
				{error && (
					<>
						<IconWrap>
							<FontAwesomeIcon icon={faCircleExclamation} />
						</IconWrap>

						<Error>{error?.message || error}</Error>
					</>
				)}
				{!error && helpText && <HelpText>{helpText}</HelpText>}
			</Wrapper>
		);
	}
);

export default MultiSelectField;
