import React from 'react';
import styled, { css } from 'styled-components';

const BoxWrap = styled.div`
	padding: ${p => (p.smallpadding === 'true' ? '30px 20px' : '40px 30px')};
	position: relative;
	text-align: ${p => p.alignment || 'left'};
	${p =>
		p.theme.media.large(css`
			flex: 1 1 448px;
		`)};

	&.no-shadow {
		padding-left: 0;
		padding-right: 0;
	}

	&.headinglevel-h2 {
		.title {
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
		}
	}

	.intro-text {
		max-width: 100%;
	}

	${p =>
		p.theme.media.smallOnly(css`
			&.no-shadow:first-of-type {
				padding-top: 0;
			}
			&.no-shadow:last-of-type {
				padding-top: 0;
				padding-bottom: 0;
			}
			.max-width {
				padding: 0;
			}
		`)};

	${p =>
		p.theme.media.smallOnly(css`
			padding: ${p =>
				`${p.theme.spacing.desktop.small} ${p.theme.spacing.desktop.xsmall}`};
		`)};

	${p =>
		(p.$shadow === 'true' &&
			css`
				${ShadowBoxStyle}
			`) ||
		css`
			${p =>
				p.theme.media.large(css`
					&:nth-child(even) {
						padding-right: 0;
					}
					&:nth-child(odd) {
						padding-left: 0;
					}
				`)};

			${p =>
				p.theme.media.mediumDown(css`
					padding-left: 0;
					padding-right: 0;
				`)};
		`}

	${p =>
		p.$shadow !== 'true' &&
		p.$nopadding === 'true' &&
		css`
			padding: 0 !important;
		`}
`;

const BadgeWrap = styled.div`
	position: absolute;
	top: ${p => `-${p.theme.spacing.tablet.xsmall}`};
	left: ${p => (p.alignment === 'center' && '0') || '30px'};
	right: ${p => (p.alignment === 'center' && '0') || 'auto'};
	margin: ${p => (p.alignment === 'center' && 'auto') || '0'};
	width: fit-content;
	color: white;
	background: ${p => p.theme.colors.green700};
	border-radius: ${p => p.theme.utils.borderRadiusSmall};
	font-weight: 700;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 24px;
	padding: ${p => `3px ${p.theme.spacing.tablet.xsmall}`};
`;

/**
 * A simple wrapper component that renders its children inside a box.
 *
 * @component
 * @param {Object} props - The properties for the Box component.
 * @param {number} props.maxPerRow - The maximum number of boxes to display per row.
 * @param {string} props.shadow - Whether the box should have a shadow.
 * @param {ReactNode} children - The content to be rendered inside the box.
 * @returns {ReactNode} - A React element representing the box with its content.
 */
export default function Box({
	children,
	maxPerRow = 3,
	shadow = 'true',
	nopadding = 'false',
	badge = '',
	...props
}) {
	if (!children) return <></>;

	return (
		<BoxWrap
			{...props}
			$shadow={shadow}
			$nopadding={nopadding}
			className={`box ${
				shadow === 'true' ? 'shadow' : 'no-shadow'
			} max-${maxPerRow} ${props?.className || ''}`}>
			{badge && (
				<BadgeWrap alignment={props?.alignment}>{badge}</BadgeWrap>
			)}
			{children}
		</BoxWrap>
	);
}

/**
 * A styled component for the box.
 * @param {Object} props - The properties for the box.
 * @returns {CSS} The styled component.
 */
export function ShadowBoxStyle() {
	return css`
		padding: ${p =>
			p.smallpadding === 'true' ? '30px 20px' : '40px 30px'};
		position: relative;
		text-align: ${p => p.alignment || 'left'};
		background: white;
		box-shadow: ${p => p.theme.utils.boxShadowHard};
		border-radius: ${p => p.theme.utils.borderRadius};
		${p =>
			p.theme.media.large(css`
				flex: 1 1 448px;
			`)};
		${p =>
			p.theme.media.mediumOnly(css`
				padding: 30px 20px;
			`)};
		${p =>
			p.theme.media.smallOnly(css`
				padding: 30px 20px;
			`)};

		// Make sure form-button is always at the bottom of the box
		.component__title-and-text {
			display: flex;
			flex-direction: column;
			height: 100%;
			> .title {
				margin-bottom: 0 !important;
			}
			> .text {
				flex-grow: 1;
				> div {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					height: 100%;
					.component__form {
						margin: 0;
						margin-top: auto;
						padding-top: 20px;
						width: 100%;
						${p =>
							p.theme.media.smallOnly(css`
								padding-top: 10px;
							`)};
						.button-popup {
							display: flex;
							width: 100%;
							justify-content: center;
						}
					}
					ul,
					ol {
						margin: 20px 0;
						${p =>
							p.theme.media.smallOnly(css`
								margin: 10px 0 20px;
							`)};
					}
					small {
						font-size: 14px;
						line-height: 22px;
						color: ${p => p.theme.colors.grey700};
					}
				}
			}
		}
	`;
}
