import React from 'react';
import styled, { css } from 'styled-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import Link, { AnchorLink } from 'components/Link';
import Error from 'components/error/Error';
import { useForm } from './FormProvider';

export const Fieldset = styled.fieldset`
	margin-bottom: 45px;
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: 30px;
		`)}
	&:last-of-type {
		margin-bottom: 20px;
	}
	legend {
		font-weight: 500;
		font-size: 24px;
		line-height: 31px;
		margin-bottom: 20px !important;
	}
	.field-options {
		list-style: none;
		padding: 0;
		margin: 0;
		li:last-of-type label {
			margin-bottom: 0;
		}
		&.horizontal {
			display: inline-flex;
			gap: 20px;
			li {
				min-width: 70px;
			}
		}
	}
`;

export const FieldGroup = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`;

export const Field = styled.div`
	flex: 1 1
		${p => (p.$width === 'half' ? 'calc(50% - 20px)' : 'calc(100% - 20px)')};
	> div {
		margin-bottom: 0;
		&:last-of-type > label {
			margin-bottom: 0;
		}
	}
`;

const PrivacyPolicy = styled.p`
	margin: 0;
`;

const FormFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 45px;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 30px;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
			align-items: flex-start;
		`)}
	button[type='submit'] {
		margin-right: 20px;
		${p =>
			p.theme.media.XSmallOnly(css`
				margin-right: 0;
			`)}
	}
`;

export const Req = styled.span`
	color: ${p => p.theme.colors.coral800};
`;

export function ContactInfoFields({ data, onChange }) {
	return (
		<Fieldset>
			<legend>Hvem er du?</legend>

			<FieldGroup>
				<Field>
					<InputField
						id="name"
						name="name"
						label="Navn"
						type="text"
						placeholder="Navn"
						defaultValue={data?.name}
						required={true}
						onChange={e =>
							onChange(
								{
									name: e?.target?.value,
								},
								true
							)
						}
					/>
				</Field>
				<Field $width="half">
					<InputField
						id="email"
						name="email"
						label="E-post"
						type="email"
						placeholder="E-post"
						defaultValue={data?.email}
						required={true}
						onChange={e =>
							onChange(
								{
									email: e?.target?.value,
								},
								true
							)
						}
					/>
				</Field>
				<Field $width="half">
					<InputField
						id="phone"
						name="phone"
						type="tel"
						label="Telefon"
						placeholder="Telefon"
						defaultValue={data?.phone}
						onChange={e =>
							onChange(
								{
									phone: e?.target?.value,
								},
								true
							)
						}
					/>
				</Field>
				<Field>
					<InputField
						id="address"
						name="address"
						label="Postadresse"
						type="text"
						placeholder="Postadresse"
						defaultValue={data?.address}
						required={true}
						onChange={e =>
							onChange(
								{
									address: e?.target?.value,
								},
								true
							)
						}
					/>
				</Field>
			</FieldGroup>
		</Fieldset>
	);
}

export function FormSubmission({
	text = 'Send inn',
	loading = false,
	error = {},
	goBack,
}) {
	const { data } = useForm();

	const readyToSubmit = !!(
		data?.name &&
		data?.email &&
		data?.address &&
		typeof data?.isPowerCustomer === 'boolean' &&
		data?.fjuttTerms &&
		data?.angrerettTerms
	);

	return (
		<>
			<PrivacyPolicy>
				Les vår{' '}
				<AnchorLink
					href="/om-nte/personvernerklaering/"
					target="_blank">
					personvernerklæring
				</AnchorLink>
				.
			</PrivacyPolicy>

			{!loading && error?.message && (
				<Error
					text={error?.message}
					resolver={error?.resolver}
					errorMessage={error?.message}
				/>
			)}

			<FormFooter>
				<Button
					type="submit"
					title={text}
					loading={loading?.toString()}
					disabled={loading || !readyToSubmit}
					icon={faCheck}>
					{text}
				</Button>
				{goBack?.title && goBack?.href && (
					<Link to={goBack.href}>{goBack.title}</Link>
				)}
			</FormFooter>
		</>
	);
}
